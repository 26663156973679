import { IconApi, IconBuildingStore, IconLock, IconSettings, IconUsers } from "@tabler/icons-react";
import { RemoveRequestDTO } from "core/lib/payment-putty/gift-cards/dto/RemoveCartDTO";
import {
  ApplyTransactionDTO,
  CheckBalanceTransactionDTO,
  CommitTransactionDTO,
  VerifyTransactionDTO,
} from "core/lib/payment-putty/gift-cards/dto/TransactionDTO";

export const APP_MODE = {
  base: "base",
  sprokit: "sprokit",
};

export const CONFIG = {
  ALLOW_CUSTOM_AUTH: false,
};

export const PERMISSIONS = {
  storePickup: [
    {
      id: "reschedule",
      name: "Reschedule Pickup",
    },
    {
      id: "printPackingSlip",
      name: "Print Packing Slip",
    },
    {
      id: "managePickupWindows",
      name: "Manage Pickup Windows",
    },
    {
      id: "editPickupSettings",
      name: "Edit Pickup Settings",
    },
    {
      id: "editPickupLocations",
      name: "Edit Pickup Locations",
    },
  ],
  paymentPutty: [
    {
      id: "refunds",
      name: "Refunds",
    },
  ],
};

export const APP_EXTENSIONS = {
  // url in app extensions = /{scope}/{id}
  REDIRECTS: {
    order: "/refunds",
    pickup: "/store-pickup",
    product: "",
    customer: "",
  },
};

export const ADMIN_TABS = [
  { href: "/admin/stores", text: "Stores", icon: <IconBuildingStore /> },
  { href: "/admin/secrets", text: "Secrets", icon: <IconLock /> },
  { href: "/admin/endpoints", text: "Flex Endpoints", icon: <IconApi /> },
  { href: "/admin/api", text: "API", icon: <IconApi /> },
  { href: "/admin/users", text: "Users", icon: <IconUsers /> },
  // { href: "/admin/store-logs", text: "Store Logs"  },
  { href: "/admin/settings", text: "Settings", icon: <IconSettings /> },
  { href: "/admin/scripts", text: "Script Manager", icon: <IconSettings /> },
  { href: "/admin/integrations", text: "Integrations", icon: <IconSettings /> },
  // { href: "/admin/logs", text: "Server Logs", icon: <IconListDetails /> },
];

export const BIGCOMMERCE = {
  CHANNELS: {},
  CUSTOM_FIELDS: {},
  PAYMENT_METHODS: {
    moneyorder: "Credit Card",
    cod: "Purchase Order",
  },
};

export const PLATFORM = {
  BIGCOMMERCE: {
    name: "BigCommerce",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/bigcommerce-icon.png",
    url: "https://www.bigcommerce.com/",
  },
  INTEGRATION_PLATFORM: {
    name: "Integration Platform",
    url: "https://www.bigcommerce.com/",
  },
  RYZOME: {
    name: "Ryzome",
    url: "https://www.bigcommerce.com/",
  },
  ABILITY_COMMERCE: {
    name: "Ability Commerce",
    logo: "https://www.abilitycommerce.com/wp-content/uploads/2018/10/AC_Favicon.jpg",
    url: "https://www.bigcommerce.com/",
  },
  AUTHORIZE_NET: {
    name: "Authorize.NET",
    logo: "https://www.authorize.net/content/dam/anet2021/images/evergreen/favicon-64x64.png",
    url: "https://www.bigcommerce.com/",
  },
  ACOUSTIC: {
    name: "Acoustic",
    logo: "https://www.acoustic.com/favicon.ico",
    url: "https://www.acoustic.com",
  },
  BAMBORA: {
    name: "Bambora",
    tooltip: "TD Online Mart by Bambora",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/TdMerchant.jpg",
    url: "https://dev.na.bambora.com/docs/",
  },
  PAYMENT_PUTTY: {
    name: "PaymentPutty",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/PaymentPutty.png",
    logoUrl: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/PaymentPuttyTagline.png",
  },
  AWS_S3: {
    name: "AWS S3",
    logo: "https://signin.aws.amazon.com/favicon.ico",
    url: "https://s3.console.aws.amazon.com",
  },
  SHIPPERHQ: {
    name: "ShipperHQ",
    logo: "https://cdn.shipperhq.com/assets/favicon-32x32-84b766cd9d2b380b0b12fedd25a44b403cc9af5525ff1fd228f17d69aa7a708c.png",
    url: "https://shipperhq.com/",
  },
};

export const EMAIL_TEMPLATES = [
  "core/email/email-notification.jsx",
  "core/email/email-wrap.jsx",
  "core/email/reset-password.jsx",
  "core/email/store-monitor.jsx",
];

export const STORE_MONITOR_FREQUENCY = [
  { value: "everyday", text: "Everyday" },
  { value: "weekday", text: "Monday - Friday" },
  { value: "weekend", text: "Saturday & Sunday" },
  { value: "monday", text: "Monday" },
  { value: "tuesday", text: "Tuesday" },
  { value: "wednesday", text: "Wednesday" },
  { value: "thursday", text: "Thursday" },
  { value: "friday", text: "Friday" },
  { value: "saturday", text: "Saturday" },
  { value: "sunday", text: "Sunday" },
];

export const TRACKING_INFO = {
  MEDIUM: [
    "Article",
    "Banner-Ad",
    "CPC",
    "Direct-Mail",
    "Email",
    "Giveaway",
    "Literature",
    "Newsletter",
    "Outdoor",
    "Press-Release",
    "Print-Advertising",
    "Radio",
    "Social-Marketing",
    "Television",
    "Video",
    "Web-Advertising",
    "White-Paper",
  ],
  SOURCE: [
    "ABC",
    "Adams-Outdoor",
    "Bing-MSN",
    "Business-Wire",
    "CBS",
    "Center-Daily-Times",
    "CPB-Journal",
    "Daily-Item",
    "ESPN",
    "Facebook",
    "Fox",
    "Google",
    "Hoovers-List",
    "InfoUSA-List",
    "Lamar-Outdoor",
    "LinkedIn",
    "LVB-Journal",
    "NBC",
    "NEB-Journal",
    "Patriot-News",
    "Press-Enterprise",
    "Sentinel",
    "Standard-Journal",
    "Sun-Gazette",
    "Twitter",
    "USA-Today",
    "Vocus",
    "Yahoo",
    "YouTube",
  ],
};

export const CONSTANTS = {
  CONFIG,
  BIGCOMMERCE,
  PLATFORM,
  TRACKING_INFO,
};

export const BIGCOMMERCE_WEBHOOK_EVENTS = [
  "store/order/*",
  "store/order/created",
  "store/order/updated",
  "store/order/archived",
  "store/order/statusUpdated",
  "store/order/message/created",
  "store/order/refund/created",
  "store/product/*",
  "store/product/deleted",
  "store/product/created",
  "store/product/updated",
  "store/product/inventory/updated",
  "store/product/inventory/order/updated",
  "store/category/*",
  "store/category/created",
  "store/category/updated",
  "store/category/deleted",
  "store/sku/*",
  "store/sku/created",
  "store/sku/updated",
  "store/sku/deleted",
  "store/sku/inventory/updated",
  "store/sku/inventory/order/updated",
  "store/customer/*",
  "store/customer/created",
  "store/customer/updated",
  "store/customer/deleted",
  "store/customer/address/created",
  "store/customer/address/updated",
  "store/customer/address/deleted",
  "store/customer/payment/instrument/default/updated",
  "store/app/uninstalled",
  "store/information/updated",
  "store/cart/*",
  "store/cart/created",
  "store/cart/updated",
  "store/cart/deleted",
  "store/cart/couponApplied",
  "store/cart/abandoned",
  "store/cart/converted",
  "store/cart/lineItem/*",
  "store/cart/lineItem/created",
  "store/cart/lineItem/updated",
  "store/cart/lineItem/deleted",
  "store/shipment/*",
  "store/shipment/created",
  "store/shipment/updated",
  "store/shipment/deleted",
  "store/subscriber/*",
  "store/subscriber/created",
  "store/subscriber/updated",
  "store/subscriber/deleted",
];

export const BIGCOMMERCE_WEBHOOK_DESCRIPTIONS = [
  { scope: "store/order/*", desc: "Subscribe to all store/order events" },
  { scope: "store/order/created", desc: "Fires if an order is created using the control panel, an app or via the API." },
  {
    scope: "store/order/updated",
    desc: "Fires when an already created order is updated. Any changes to an existing order will fire this webhook. Updates can include changing the status, updating a coupon or changing an address.",
  },
  { scope: "store/order/archived", desc: "Order is archived" },
  { scope: "store/order/statusUpdated", desc: "This will only fire if the order status has changed. Such as Pending to Awaiting Payment" },
  { scope: "store/order/message/created", desc: "Order message is created by customer or in control panel" },
  { scope: "store/order/refund/created", desc: "A refund has been submitted against an order" },
  { scope: "store/product/*", desc: "Subscribe to all store/product events" },
  { scope: "store/product/deleted", desc: "Product is deleted" },
  { scope: "store/product/created", desc: "A new product is created" },
  {
    scope: "store/product/updated",
    desc: "Occurs when product details are edited. For a full list of product fields that trigger an updated event, see Product updated events below",
  },
  { scope: "store/product/inventory/updated", desc: "Product inventory is updated." },
  {
    scope: "store/product/inventory/order/updated",
    desc: "Fires if a product�s inventory is decremented or incremented, including when an order is placed. Webhook responds to inventory updates made using the control panel, CSV import, API or an app.",
  },
  { scope: "store/category/*", desc: "Subscribe to all store/category events" },
  { scope: "store/category/created", desc: "Category is created" },
  { scope: "store/category/updated", desc: "Category is updated" },
  { scope: "store/category/deleted", desc: "Category is deleted" },
  { scope: "store/sku/*", desc: "Subscribe to all store/sku events" },
  { scope: "store/sku/created", desc: "A new sku is created" },
  { scope: "store/sku/updated", desc: "SKU is updated" },
  { scope: "store/sku/deleted", desc: "SKU is deleted" },
  { scope: "store/sku/inventory/updated", desc: "SKU is updated" },
  {
    scope: "store/sku/inventory/order/updated",
    desc: "This will fire when the inventory is updated via API, the control panel, when an order is placed and when an order is refunded and the inventory is returned to stock. This hook will fire based on a store�s Inventory settings.",
  },
  { scope: "store/customer/*", desc: "Subscribe to all store/customer events" },
  { scope: "store/customer/created", desc: "A new customer is created" },
  { scope: "store/customer/updated", desc: "Customer is updated. Does not currently track changes to the customer address." },
  { scope: "store/customer/deleted", desc: "Customer is deleted" },
  { scope: "store/customer/address/created", desc: "Customer address is created" },
  { scope: "store/customer/address/updated", desc: "Customer address is updated" },
  { scope: "store/customer/address/deleted", desc: "Customer address is deleted" },
  { scope: "store/customer/payment/instrument/default/updated", desc: "Customer default payment instrument is updated" },
  { scope: "store/app/uninstalled", desc: "Occurs when a client store is cancelled and uninstalled from the platform" },
  {
    scope: "store/information/updated",
    desc: "Occurs when changes are made to store settings. For a full list of fields that can trigger this event, see Store information updated events below",
  },
  { scope: "store/cart/*", desc: "Subscribe to all cart events. This will also subscribe you to cart/lineItem." },
  {
    scope: "store/cart/created",
    desc: "This webhook will fire whenever a new cart is created, either via a storefront shopper adding their first item to the cart, or when a new cart is created via an API consumer. If it is from the storefront, then it fires when the first product is added to a new session.(The cart did not exist before). For the API it means a POST to /carts, (V3 and Storefront API). The store/cart/updated hook will also fire.",
  },
  {
    scope: "store/cart/updated",
    desc: "This webhook is fired whenever a cart is modified through the changes in its line items. Eg. when a new item is added to a cart or an existing item�s quantity is updated. This hook also fires when the email is changed during guest checkout or when an existing item is deleted. The payload will include the ID of the cart being updated. This webhook also fires along with the cart created hook, because the first product being added to an empty cart triggers an update. It will also fire for the following events:\n- Logging into customer account after creating a cart (email is inherited from customer account email)\n- Entering email address via guest checkout\n-Changing the email in guest checkout",
  },
  {
    scope: "store/cart/deleted",
    desc: "This webhook will fire whenever a cart is deleted. This will occur either when all items have been removed from a cart and it is auto-deleted, or when the cart is explicitly removed via a DELETE request by an API consumer. This ends the lifecycle of the cart. The store/cart/updated webhook will also fire when the last item is removed.",
  },
  {
    scope: "store/cart/couponApplied",
    desc: "This webhook will fire whenever a new coupon code is applied to a cart. It will include the ID of the coupon code",
  },
  {
    scope: "store/cart/abandoned",
    desc: "This webhook will fire once after a cart is abandoned. A cart is considered abandoned if no changes have been made for at least one hour after the last modified property. This hook is available for all store plans, regardless of whether the Abandoned Cart Saver feature is enabled.",
  },
  {
    scope: "store/cart/converted",
    desc: "This hook fires when a cart is converted into an order, which is typically after the payment step of checkout on the storefront. At this point, the cart is no longer accessible and has been deleted. This hook returns both the cart ID and order ID for correlation purposes.",
  },
  {
    scope: "store/cart/lineItem/*",
    desc: "Subscribe to all cart line item events. This webhook will fire when a change occurs to line items in the cart. This can be when items are added to a cart, removed or updated.(Ex. change to quantity, product options or price).",
  },
  { scope: "store/cart/lineItem/created", desc: "When a new item is added to the cart" },
  { scope: "store/cart/lineItem/updated", desc: "When an item�s quantity has changed or the product options change." },
  { scope: "store/cart/lineItem/deleted", desc: "When an item is deleted from the cart" },
  { scope: "store/shipment/*", desc: "Subscribe to all store/shipment events" },
  { scope: "store/shipment/created", desc: "Shipment is created" },
  { scope: "store/shipment/updated", desc: "Shipment is updated" },
  { scope: "store/shipment/deleted", desc: "Shipment is deleted" },
  { scope: "store/subscriber/*", desc: "Subscribe to all store/subscriber events" },
  { scope: "store/subscriber/created", desc: "Subscriber is created" },
  { scope: "store/subscriber/updated", desc: "Subscriber is updated" },
  { scope: "store/subscriber/deleted", desc: "Subscriber is deleted" },
];

// eslint-disable-next-line no-unused-vars
const PAYMENTPUTTY_PROVIDER_EXAMPLE = {
  // Controls visibility of the provider in the UI
  enabled: true,
  // The name of the provider
  name: "Example",
  // Upload logos to the `mojoactive-bigcommerce` bucket in S3 and paste the link here
  logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/example.jpg",
  // Controls which fields are required by the gift card provider
  // These field fields can be pulled from the Secrets util.
  // ex. const MerchantId = await Secrets.Store.Value(req.store,  'PaymentPutty.Example.MerchantId');
  fields: [
    {
      // this key will be saved in the secrets table following this naming convention:
      // ex. PaymentPutty.Example.MerchantId
      key: "MerchantId",
      // the label that will be displayed to the user
      label: "Custom Label",
    },
  ],
};

export const PAYMENTPUTTY_PROVIDERS = {
  // see the example above for more details
  SVS: {
    enabled: true,
    name: "SVS",
    type: "giftCard",
    desc: "A world leader in Gift Cards and Stored Value Services. SVS provides support to clients to process over 1.3 billion gift card transactions annually.",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/SVS.jpg",
    fields: [{ key: "MerchantId" }, { key: "StoreNumber" }, { key: "Division" }, { key: "Username" }, { key: "Password" }],
  },
  Shift4: {
    enabled: true,
    name: "Shift4",
    type: "giftCard",
    desc: "Shift4 is the leader in secure payment processing solutions, including point-to-point encryption, tokenization, EMV technology and point-of-sale (POS) systems.",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/Shift4.png",
    docs: "https://myportal.shift4.com/index.cfm?action=development.shift4api",
    fields: [{ key: "MerchantId" }, { key: "ClientKey" }, { key: "MerchantUrl" }],
  },
  STS: {
    enabled: true,
    name: "STS",
    type: "giftCard",
    desc: "STS develops payments acceptance software for retailers, airlines and transportation operators that maximite choice and flexibility.",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/STS.png",
    docs: "https://smarttransactionsystems.com/developers/",
    fields: [{ key: "MerchantId" }, { key: "TerminalId" }, { key: "ClientKey" }, { key: "MerchantUrl" }],
  },
  Valutec: {
    enabled: true,
    name: "Valutec",
    type: "giftCard",
    desc: "Valutec Card Solutions is a full service gift & loyalty card solutions provider.",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/Valutec.jpg",
    fields: [{ key: "TerminalId" }, { key: "ClientKey" }, { key: "MerchantUrl" }],
  },
  TSYS: {
    enabled: true,
    name: "TSYS",
    type: "giftCard",
    desc: "TSYS, a Global Payments company, is the payment stack for the future, powered by unmatched expertise",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/TSYS.png",
    docs: "https://developers.tsys.com",
    fields: [{ key: "ClientName" }, { key: "ClientSite" }, { key: "ClientKey" }, { key: "MerchantUrl" }],
  },
  Voucherify: {
    enabled: true,
    name: "Voucherify",
    type: "giftCard",
    desc: "An affordable, API-first promotion software that requires minimum effort to integrate and offers plenty of features",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/Voucherify.png",
    docs: "https://docs.voucherify.io/docs/authentication",
    fields: [{ key: "MerchantId" }, { key: "ClientKey" }, { key: "MerchantUrl" }],
  },
  GiveX: {
    enabled: true,
    name: "GiveX",
    type: "giftCard",
    desc: "Givex provides the restaurant, retail, and QSR industries with cutting-edge pos (epos) solutions, management systems, gift cards, loyalty programs.",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/GiveX.jpg",
    fields: [{ key: "Host" }, { key: "HostPort" }, { key: "TimeoutMS" }, { key: "UserName" }, { key: "Password" }, { key: "PreAuthOnly" }],
  },
  SmarterCommerce: {
    enabled: true,
    name: "SmarterCommerce",
    type: "creditCard",
    desc: "SmarterCommerce is the leader in unified commerce for JD Edwards including Credit Card Processing, Ecommerce, Point of Sale, and Call Center solutions",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/SmarterCommerce.png",
    docs: "https://www.smartercommerce.net/product-guides",
    fields: [{ key: "ServiceName" }, { key: "AccessToken" }, { key: "ConnectionName" }, { key: "Owner" }, { key: "URL" }],
  },
  Bambora: {
    enabled: true,
    name: "Bambora",
    label: "TD Online Mart by Bambora",
    type: "creditCard",
    desc: "TD Merchant Solutions offers credit card readers and Point of Sale (POS) systems that have security features and mobility options that support how you",
    logo: [
      "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/TdMerchant.jpg",
      "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/bambora.png",
    ],
    docs: "https://dev.na.bambora.com/docs/guides/",
    fields: [{ key: "MerchantId" }, { key: "Passcode" }, { key: "ReportingPasscode" }, { key: "APIHash" }],
  },
};

export const PAYMENTPUTTY_WEBHOOK_EVENTS = [
  "payment-putty/v2/apply",
  "payment-putty/v2/check",
  "payment-putty/v2/commit",
  "payment-putty/v2/establish",
  "payment-putty/v2/finalize",
  "payment-putty/v2/remove",
  "payment-putty/v2/verify",
  "payment-putty/v2/authorize-and-capture",
  "payment-putty/v2/refund",
  "payment-putty/v2/void",
];

export const PAYMENTPUTTY_WEBHOOK_DESCRIPTIONS = [
  { scope: "payment-putty/v2/apply", desc: "Not currently used." },
  { scope: "payment-putty/v2/check", desc: "Step 1 in normal flow." },
  { scope: "payment-putty/v2/commit", desc: "Step 2 in normal flow." },
  { scope: "payment-putty/v2/establish", desc: "Used for clients that require check balance outside of the cart" },
  { scope: "payment-putty/v2/finalize", desc: "Step 3 in normal flow." },
  { scope: "payment-putty/v2/remove", desc: "Not currently used." },
  { scope: "payment-putty/v2/verify", desc: "TODO - Look into how this is currently used." },
  { scope: "payment-putty/v2/authorize-and-capture", desc: "Authorize And Capture Credit Card" },
  { scope: "payment-putty/v2/refund", desc: "Return an amount back to the credit card" },
  { scope: "payment-putty/v2/void", desc: "Cancels that transaction" },
];

export const PAYMENTPUTTY_WEBHOOK_PAYLOADS = [
  { scope: "payment-putty/v2/apply", payload: JSON.stringify(new ApplyTransactionDTO(), null, 2) },
  { scope: "payment-putty/v2/check", payload: JSON.stringify(new CheckBalanceTransactionDTO(), null, 2) },
  { scope: "payment-putty/v2/commit", payload: JSON.stringify(new CommitTransactionDTO(), null, 2) },
  { scope: "payment-putty/v2/establish", payload: {} },
  { scope: "payment-putty/v2/finalize", payload: JSON.stringify(new CommitTransactionDTO(), null, 2) },
  { scope: "payment-putty/v2/remove", payload: JSON.stringify(new RemoveRequestDTO(), null, 2) },
  { scope: "payment-putty/v2/verify", payload: JSON.stringify(new VerifyTransactionDTO(), null, 2) },
  { scope: "payment-putty/v2/authorize-and-capture", payload: JSON.stringify({}, null, 2) },
  { scope: "payment-putty/v2/refund", payload: JSON.stringify({}, null, 2) },
  { scope: "payment-putty/v2/void", payload: JSON.stringify({}, null, 2) },
];

// You can use https://brandfetch.com/ to get the logo and image for your integration
// Then use https://www.figma.com/file/Z7Yz6VvDsP1vy2k9J4zmzh/Integration-Brand-Assets?node-id=0%3A1&t=KfEXM45g4KmPphKH-1 to export at correct dimensions
export const INTEGRATIONS = {
  ShipStation: {
    enabled: true,
    name: "ShipStation",
    desc: "ShipStation is a web-based shipping solution that helps you get orders out the door quickly and easily.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/shipstation.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/shipstation-icon.png",
    docs: "https://www.shipstation.com/docs/api/",
    url: "https://www.shipstation.com/",
    fields: [{ key: "API_KEY" }, { key: "API_SECRET" }, { key: "PARTNER_KEY" }],
  },
  Klaviyo: {
    enabled: true,
    name: "Klaviyo",
    desc: "Klaviyo is a marketing automation platform that helps you collect, analyze, and act on your customer data.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/klaviyo.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/klaviyo-icon.png",
    docs: "https://developers.klaviyo.com/en/reference/api_overview",
    url: "https://www.klaviyo.com/",
    fields: [{ key: "API_KEY" }],
  },
  TaxJar: {
    enabled: true,
    name: "TaxJar",
    desc: "TaxJar is a sales tax automation platform that helps merchants calculate sales tax, file sales tax returns, and manage exemption certificates.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/taxjar.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/taxjar-icon.png",
    docs: "https://developers.taxjar.com/api/reference/",
    url: "https://www.taxjar.com/",
    fields: [{ key: "API_KEY" }],
  },
  AmazonSeller: {
    enabled: true,
    name: "AmazonSeller",
    desc: "Amazon Seller is a platform that helps you sell your products on Amazon.com.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/amazon-seller.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/amazon-seller-icon.png",
    docs: "https://developer.amazonservices.com/",
    url: "https://sellercentral.amazon.com/",
    fields: [{ key: "API_KEY" }, { key: "API_SECRET" }],
  },
  USPS: {
    enabled: true,
    name: "USPS",
    desc: "USPS is a shipping carrier that helps you ship your products to your customers.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/usps.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/usps-icon.png",
    docs: "https://www.usps.com/business/web-tools-apis/welcome.htm",
    url: "https://www.usps.com/",
    fields: [{ key: "USER_ID" }, { key: "PASSWORD" }],
  },
  FedEx: {
    enabled: true,
    name: "FedEx",
    desc: "FedEx is a shipping carrier that helps you ship your products to your customers.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/fedex.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/fedex-icon.png",
    docs: "https://developer.fedex.com/",
    url: "https://fedex.com/",
    fields: [{ key: "API_KEY" }, { key: "SECRET_KEY" }, { key: "SHIPPING_ACCOUNT" }],
  },
  GoDataFeed: {
    enabled: true,
    name: "GoDataFeed",
    desc: "GoDataFeed is a product feed management platform that helps you list your products on shopping channels.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/godatafeed.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/godatafeed-icon.png",
    docs: "https://developer.godatafeed.com/#introduction",
    url: "https://www.godatafeed.com/",
    fields: [{ key: "CONSUMER_KEY" }],
  },
  Braintree: {
    enabled: true,
    name: "Braintree",
    desc: "Braintree is a payment gateway that helps you accept payments from your customers.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/braintree.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/braintree-icon.png",
    docs: "https://developer.paypal.com/braintree/docs/start/hello-server/node",
    url: "https://developer.paypal.com/braintree/docs",
    fields: [{ key: "ENVIRONMENT" }, { key: "MERCHANT_ID" }, { key: "PUBLIC_KEY" }, { key: "PRIVATE_KEY" }, { key: "CLIENT_AUTHORIZATION" }],
  },
  B2B: {
    enabled: true,
    name: "B2B",
    desc: "B2B is a platform that helps you manage your wholesale customers.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/bundle-b2b.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/bundle-b2b-icon.png",
    docs: "https://bundleb2b.stoplight.io/docs/openapi/quick-start",
    url: "https://www.bigcommerce.com/apps/b2b-edition/",
    fields: [{ key: "API_KEY" }, { key: "API_KEY_SECONDARY" }],
  },
};
