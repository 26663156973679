import { DataProvider as GeneratedDataProvider, ResponseError, ResponseOk, Request, RequestThrottle, setConfig } from "@mojoactive/bc-api";

if (typeof window === "undefined") {
  const { EventSpan } = require("../core/lib/tracing");
  setConfig({ EventSpan });
}

const Promotions = {
  GetAll: async (ctx) => Request(ctx, "get", `/v3/promotions`),
  Coupons: {
    GetAll: async (ctx, promotionId) => Request(ctx, "get", `/v3/promotions/${promotionId}/codes`),
  },
};

const DataProvider = {
  ...GeneratedDataProvider,
  Promotions,
};

export { DataProvider, Request, RequestThrottle, ResponseError, ResponseOk };
